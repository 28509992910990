import * as React from 'react';

export interface IGlobalContext {
  language: string;
}

export interface IPropsWithGlobalContext {
  globalContext: IGlobalContext;
}

export const defaultContext: IGlobalContext = {
  language: 'en',
};

export const GlobalContext = React.createContext<IGlobalContext>(defaultContext);

// HOC to provide the globalContext to the wrapped component.
// Since TypeScript 3.2, there is an issue with HOC.
// It may be due to a bug in TypeScript itself... So using `any` "solves" the issue...
// cf. https://stackoverflow.com/questions/53556160/react-hoc-and-typescript-3-2
export function withGlobalContext<P extends IPropsWithGlobalContext>(
  WrappedComponent: React.ComponentType<P>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.ComponentType<any> {
  return function ComponentWithGlobalContext(props) {
    return (
      <GlobalContext.Consumer>
        {(globalContext: IGlobalContext) => <WrappedComponent globalContext={globalContext} {...props} />}
      </GlobalContext.Consumer>
    );
  };
}
