import { useRouteError } from 'react-router';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

export const ErrorBoundary = () => {
  const error: Error = useRouteError() as Error;

  return (
    <div style={{ height: '100vh' }}>
      <SgwtHelpCenter
        applicationId="designlab"
        messageOnly
        messageTemplate={`Hello support.
        
I encountered an issue while using the service.
-----------------
Technical details
Error message: ${error.message}
Error stack: ${error.stack}
        `}
      />

      <sg-error-page
        code={500}
        action-button-label="Back to home"
        action-button-link="/"
        contact-us="help-center"
        fullscreen={true}
        description-label={error.message}
        title-label="SGM Design Lab - Error"
      />
    </div>
  );
};
