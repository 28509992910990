import { BUS_TOPIC_SGCONNECT_USER_INFO, getWidgetBus } from '@sgwt/react-shared-libs';
import { IUserSgConnect } from '../interfaces/user';

const UNAUTHORIZED_PAGE = 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://designlab.sgmarkets.com';
const DESIGN_LAB_IAM_RESOURCE_ID = 'SGM_DESIGN_LAB';

export enum UserPemission {
  DEMONSTRATOR_PERMISSION = 'demonstrator',
  ACCESS_PERMISSION = 'sgm-designlab-access',
  ADMINISTRATION_PERMISSION = 'administration',
  SHARE_PERMISSION = 'external-share',
}

export function hasUserPermission(user: IUserSgConnect, permission: UserPemission): boolean {
  return user.user_authorization.some((autho) => {
    return autho.resource === DESIGN_LAB_IAM_RESOURCE_ID && autho.permissions.some((perm) => perm.name === permission);
  });
}

const canAccess = (user: IUserSgConnect) => {
  // User is internal user, so access granted...
  if (user.is_sg_group_user === 'true') {
    return true;
  }
  // User is external. So we search for the good permission on the good IAM resource...
  return hasUserPermission(user, UserPemission.ACCESS_PERMISSION);
};

// Check if the user is allowed to use the service...
export function checkAccessRights() {
  const bus = getWidgetBus();
  // We first check if we already have the userInfo information...
  const userInfo: IUserSgConnect | undefined = bus.dangerouslyGetCurrentValue(BUS_TOPIC_SGCONNECT_USER_INFO);
  if (!userInfo) {
    // Not yet, so we subscribe to this topic on the bus
    const handle = bus.subscribe(BUS_TOPIC_SGCONNECT_USER_INFO, (updatedUserInfo: IUserSgConnect | undefined) => {
      if (updatedUserInfo) {
        bus.unsubscribe(handle);
        if (!canAccess(updatedUserInfo)) {
          window.location.replace(UNAUTHORIZED_PAGE);
        }
      }
    });
  } else if (!canAccess(userInfo)) {
    window.location.replace(UNAUTHORIZED_PAGE);
  }
}
