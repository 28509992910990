import { createRoot } from 'react-dom/client';
import { defineCustomElements } from '@sg-bootstrap/components/loader';
import { SgwtAuthenticationProvider } from '@sgwt/react-shared-libs';
import { SgwtConnect, SgwtSplashScreen, SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import './general-types';
import { App } from './App';
import { checkAccessRights } from './services';

defineCustomElements(window);

const config = window.globalConfiguration;

const elt = document.querySelector('#root');
if (elt) {
  checkAccessRights();
  const root = createRoot(elt);
  root.render(
    <SgwtWidgetContextProvider infrastructure="azure">
      <SgwtConnect
        clientId={config.sgConnect.clientId}
        scope="openid profile mail api.sg-bootstrap-playground.v1 api.sg-bootstrap-playground.write app.sgbs-playground.v1 api.manage-contacts.v1"
        authorizationEndpoint={config.sgConnect.endpoint}
        redirectUri={config.sgConnect.redirectUri}
      />
      <SgwtWebAnalytics
        siteId={`${config.matomo}`}
        apmRumEnabled={config.apm.enabled}
        apmRumServerurl={config.apm.serverUrl}
        apmRumServiceName={config.apm.serviceName}
      />
      <SgwtSplashScreen applicationId="designlab" />
      <SgwtAuthenticationProvider autoSignIn withSgmServiceLoader>
        <App />
      </SgwtAuthenticationProvider>
    </SgwtWidgetContextProvider>
  );
}
