import { getRequestHeaders } from '@sgwt/react-shared-libs';
import { IPlayground } from '../interfaces';
import { LogEvent } from './logger';
import { encodeData } from './utils.service';
import { PRIVACY_KEYS } from '../types';

const BASE_API = window.globalConfiguration.apiUrl;
const PLAYGROUND_API_URL = `${BASE_API}/sgbs-playground/`;

const PLAYGROUNDS_PER_PAGE = 16;
const RECENT_ACTIVITY_LIMIT = 4;
const CONTENT_TYPE = 'application/json';

/**
 * Get a paginated list of Playgrounds.
 */
const getPlaygrounds = async (
  page: number,
  search: string,
  privacy: PRIVACY_KEYS,
  template: boolean,
  limit: number = PLAYGROUNDS_PER_PAGE
) => {
  const start = (page - 1) * limit;
  let url = `${PLAYGROUND_API_URL}?privacy=${privacy}&template=${template}&start=${start}&limit=${limit}`;
  if (search) {
    url += `&q=${search}`;
  }
  return fetch(url, { headers: getRequestHeaders() }).then((response) => response.json());
};

const getRecentActivity = async () => {
  const url = `${PLAYGROUND_API_URL}recent?limit=${RECENT_ACTIVITY_LIMIT}`;
  return fetch(url, { headers: getRequestHeaders() }).then((response) => response.json());
};

/**
 * Get one specific Playground.
 */
const get = async (playgroundId: string) =>
  fetch(PLAYGROUND_API_URL + playgroundId, { headers: getRequestHeaders() }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const getCapture = async (playgroundId: string) =>
  fetch(`${BASE_API}/capture/${playgroundId}`, { headers: getRequestHeaders() }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

/**
 * Save a Playground.
 */
const save = async (playground: IPlayground) =>
  fetch(PLAYGROUND_API_URL, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'POST',
    // Keep only parameters accepted by the Swagger, otherwise the request is rejected by the WAF (code 422).
    body: JSON.stringify({
      name: playground.name,
      code: encodeData(playground.code),
      description: playground.description,
      team: playground.team,
      tags: playground.tags,
      privacy: playground.privacy,
      dark: playground.dark || false,
      sgbsVersion: playground.sgbsVersion,
      metadata: JSON.stringify(playground.metadata),
    }),
  }).then((response) => response.json());

/**
 * Update an existing Playground.
 */
const update = async (playground: IPlayground) =>
  fetch(`${PLAYGROUND_API_URL}${playground.shortId}`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'PUT',
    body: JSON.stringify({
      ...playground,
      code: encodeData(playground.code),
      metadata: JSON.stringify(playground.metadata),
    }),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

/**
 * Delete a Playground.
 */
const remove = async (playgroundID: string) =>
  fetch(PLAYGROUND_API_URL + playgroundID, {
    headers: getRequestHeaders(),
    method: 'DELETE',
  });

/**
 * Send log events.
 */
const logs = async (events: LogEvent[]) =>
  fetch(`${BASE_API}/logs`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'POST',
    body: JSON.stringify({ events }),
  });

const getAvailableVersions = async () =>
  fetch(`${BASE_API}/sgbs/versions`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const api = {
  get,
  getPlaygrounds,
  getCapture,
  getRecentActivity,
  remove,
  save,
  update,
  logs,
  getAvailableVersions,
};

export default api;
