import { adjectives, animals, colors, Config, starWars, uniqueNamesGenerator } from 'unique-names-generator';
import { Theme } from '../types';

const nameGeneratorConfig: Config = {
  dictionaries: [adjectives, colors, [...animals, ...starWars]],
  separator: ' ',
  style: 'capital',
  length: 3,
};

export const generatePGName = () => uniqueNamesGenerator(nameGeneratorConfig);

/**
 * Due to WAF (DT-zone firewall) security rules, some Playground code can be considered as a security
 * issue, and therefore the POST / PUT requests can be rejected. To avoid that, we encode the `code`
 * property of the Playground when the request comes from SG Markets domain.
 */
export const encodeData = (code: string) => btoa(unescape(encodeURIComponent(code)));

export interface SgBootstrapResources {
  version: {
    full: string;
    major?: number;
    minor?: number;
    patch?: number;
  };
  icons: string;
  css: string;
  js: string;
  components: string;
}

/**
 * Returns the URL of the SG Bootstrap resources (icons, CSS, JS and components),
 * depending on the requested version: `main`, `experiment-***` or `major.minor.patch`.
 */
export const getBootstrapResources = (version: string, theme: Theme, latest: string): SgBootstrapResources => {
  const { cdn } = window.globalConfiguration;
  if (version === 'main') {
    return {
      version: {
        full: version,
      },
      icons: `${cdn}/v5/${latest}/icons/index.min.css`,
      css: `${cdn}/v5/${latest}/core/css/sg-bootstrap-${theme}.min.css`,
      js: `${cdn}/v5/${latest}/core/js/bootstrap.bundle.min.js`,
      components: `${cdn}/v5/${latest}/components/esm/components.js`,
    };
  }
  if (version.startsWith('experiment-')) {
    // For `experiment-***` versions, we assume that we run with Bootstrap v5+
    // For v4: https://sgithub.fr.world.socgen/pages/sgbootstrap/sg-bootstrap/${version}/@sg-bootstrap/core/build/sg-bootstrap-${theme}.min.css
    // For v5: https://sgithub.fr.world.socgen/pages/sgbootstrap/sg-bootstrap/${version}/@sg-bootstrap/core/dist/css/sg-bootstrap-${theme}.min.css
    return {
      version: {
        full: version,
      },
      icons: `${cdn}/v5/${latest}/icons/index.min.css`,
      css: `https://sgithub.fr.world.socgen/pages/sgbootstrap/sg-bootstrap/${version}/@sg-bootstrap/core/dist/css/sg-bootstrap-${theme}.min.css`,
      js: `${cdn}/v5/${latest}/core/js/bootstrap.bundle.min.js`,
      components: `https://sgithub.fr.world.socgen/pages/sgbootstrap/sg-bootstrap/${version}/@sg-bootstrap/components/dist/esm/components.js`,
    };
  }
  // Split the version
  const [major, minor, patch] = version.split('.').map(Number);
  if (major >= 5) {
    return {
      version: {
        full: version,
        major,
        minor,
        patch,
      },
      icons: `${cdn}/v5/${version}/icons/index.min.css`,
      css: `${cdn}/v5/${version}/core/css/sg-bootstrap-${theme}.min.css`,
      js:
        minor >= 6
          ? `${cdn}/v5/${version}/core/js/bootstrap.bundle.min.js`
          : // Bootstrap JS was not embedded in versions [5.0, 5.5]
            'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js',
      components: `${cdn}/v5/${version}/components/esm/components.js`,
    };
  }
  // For SG Bootstrap v4
  return {
    version: {
      full: version,
      major,
      minor,
      patch,
    },
    icons: `${cdn}/v4/icons/index.min.css`,
    css: `${cdn}/v4/${version}/sg-bootstrap-${theme}.min.css`,
    js: `${cdn}/v4/${version}/sg-bootstrap.min.js`,
    components: `${cdn}/v4/components/${version}/esm/components.js`,
  };
};

// Sort versions from the most recent to the oldest.
export const sortVersions = (v1: string, v2: string) => {
  const [v1Major, v1Minor, v1Patch] = v1.split('.').map(Number);
  const [v2Major, v2Minor, v2Patch] = v2.split('.').map(Number);
  if (v1Major !== v2Major) {
    return v2Major - v1Major;
  }
  if (v1Minor !== v2Minor) {
    return v2Minor - v1Minor;
  }
  return v2Patch - v1Patch;
};
