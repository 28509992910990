import { BUS_TOPIC_SGCONNECT_USER_INFO, getWidgetBus } from '@sgwt/react-shared-libs';
import { SgwtConnectHTMLElement } from '@sgwt/sgwt-widgets-react';
import { IUserSgConnect } from '../interfaces/user';

export function getUserInfo(): IUserSgConnect | undefined {
  const bus = getWidgetBus();
  const userInfo: IUserSgConnect | undefined = bus.dangerouslyGetCurrentValue(BUS_TOPIC_SGCONNECT_USER_INFO);
  return userInfo;
}

/**
 * Return if connected user is internal
 */
export function isSGUser(): boolean {
  const userInfo = getUserInfo();
  return !!userInfo && userInfo['is_sg_group_user'] === 'true';
}

export function isOnLAN(): boolean {
  const userInfo = getUserInfo();
  return !!userInfo && userInfo.origin_network === 'LAN';
}

/**
 *
 * Return connected user email address
 */
export function getUserSub(): string {
  const widget = document.querySelector('sgwt-connect') as SgwtConnectHTMLElement;
  if (!widget.sgwtConnect) {
    return '';
  }
  const tokenClaims = widget.sgwtConnect.getIdTokenClaims();
  return tokenClaims?.sub ?? '';
}
