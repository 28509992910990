import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';

export const Footer = () => (
  <SgwtMiniFooter
    type="micro"
    container="container-fluid"
    mode="sg-markets"
    contact-us-by-help-center="sgwt-help-center"
  />
);
