interface SgwtWebAnalyticsHTMLElement extends HTMLElement {
  trackEvent(category: string, action: string, name?: string, value?: string): void;
  trackSearch(keyword: string, category: string, count: number): void;
  trackLink(href: string, linkType: string): void;
  trackPageView(href?: string, title?: string): void;
}

export function trackEvent(event: string, value: string) {
  const widget = document.querySelector('sgwt-web-analytics') as SgwtWebAnalyticsHTMLElement;
  widget?.trackEvent(event, value);
}
