import { getRequestHeaders } from '@sgwt/react-shared-libs';
import { default as html2canvas } from 'html2canvas';
import { IPlayground } from '../interfaces';
import { encodeData } from './utils.service';

const BASE_API = window.globalConfiguration.apiUrl;

export const captureAndSave = async (playground: IPlayground) => {
  const iframe = document.querySelector<HTMLIFrameElement>('iframe.preview-iframe');

  if (iframe && iframe.contentWindow) {
    const { body } = iframe.contentWindow.document;
    const wrapper = body.querySelector<HTMLDivElement>('div.preview-wrapper');
    html2canvas(wrapper || body, {
      allowTaint: false,
      logging: true,
      useCORS: true,
      removeContainer: false,
      backgroundColor: null,
      windowWidth: 1024,
      windowHeight: 768,
      width: 1024,
      height: 768,
    })
      .then(async (canvas: HTMLCanvasElement) => {
        const dataUrl = canvas.toDataURL('image/png');
        const encodedCapture = encodeData(dataUrl);
        return fetch(`${BASE_API}/capture/${playground.shortId}`, {
          headers: {
            ...getRequestHeaders(),
            'Content-Type': 'application/json',
          },
          method: 'POST',
          // Keep only parameters accepted by the Swagger, otherwise the request is rejected by the WAF (code 422).
          body: JSON.stringify({
            content: encodedCapture,
          }),
        }).then((response) => response.json());
      })
      .catch((error) => {
        console.error(`Could not generate screenshot for the Playground ${playground.shortId}`, error);
      });
  }
};
