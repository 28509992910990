import { Link, NavLink } from 'react-router-dom';
import { UserToolbar } from './UserToolbar';

export const Navbar = () => {
  const { href } = window.location;
  const onEditor = href.includes('/editor') || href.includes('/create');

  return (
    <nav className={`navbar ${onEditor ? 'navbar-sm' : ''}`}>
      <div className="navbar-title">
        <Link to="/" className="navbar-title-link">
          <div className="navbar-logo">
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="32" height="32" fill="white"></rect>
              <rect x="1" y="1" width="30" height="15" fill="var(--bs-socgen)"></rect>
              <rect x="1" y="16" width="30" height="15" fill="black"></rect>
              <rect x="6" y="15" width="20" height="2" fill="white"></rect>
            </svg>
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">
            Design
            <br />
            Lab
          </div>
        </Link>
        <button
          className="navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-offset="0,4"
          aria-expanded="true"
        >
          <svg
            className="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="2" y="6" width="20" height="2" fill="currentColor"></rect>
            <rect x="2" y="11" width="20" height="2" fill="currentColor"></rect>
            <rect x="2" y="16" width="20" height="2" fill="currentColor"></rect>
          </svg>
        </button>
      </div>
      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <li className="navbar-item">
            <NavLink className="navbar-link" to="/mine">
              Projects
            </NavLink>
          </li>
          <li className="navbar-item">
            <NavLink className="navbar-link" to="/all">
              Community
            </NavLink>
          </li>
          <li className="navbar-item">
            <NavLink className="navbar-link" to="/components">
              Library
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="navbar-toolbar">
        <Link
          to="/create"
          target={onEditor ? '_blank' : ''}
          style={{ flexShrink: 0 }}
          className={`btn ${onEditor ? '' : 'btn-lg'} btn-discreet-primary align-self-center flex-nowrap`}
        >
          {onEditor ? 'New playground' : 'Create a playground'}
        </Link>
        <div className="user-toolbar">
          <UserToolbar />
        </div>
      </div>
    </nav>
  );
};
