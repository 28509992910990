import { AccountCenterThemeEvent, SgwtAccountCenter, SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

export const UserToolbar = () => {
  const changeTheme = (evt: AccountCenterThemeEvent) => {
    // The version is defined in the index.html
    window.loadSgBootstrap(window.SG_BOOTSTRAP_VERSION, evt.theme);
  };

  return (
    <header className="d-flex align-items-center justify-content-end ac">
      <SgwtAccountCenter
        availableLanguages={['en']}
        authentication="sg-connect"
        themeSwitcher
        onThemeChanged={changeTheme}
      />
      <SgwtHelpCenter applicationId="designlab" messageOnly />
    </header>
  );
};
